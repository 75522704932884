import { useQuery } from 'react-query';
import { TaskTemplateAPI } from 'api';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export const useGetTaskTemplateLibrary = ({
  params,
  ...config
}: {
  params: { [key: string]: string | number } | {};
}) => {
  const [debouncedParams, setDebouncedParams] = useState(params);
  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedParams(params);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [params]);

  const isQueryEnabled =
    Object.keys(params).length === 0 || params['lfilt.NameSearch']?.length >= 3;

  return useQuery(
    ['task-template-library', debouncedParams],
    async () => {
      return await TaskTemplateAPI.getTaskTemplateLibrary({ params: debouncedParams });
    },
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0,
      keepPreviousData: true,
      enabled: isQueryEnabled,
      ...config
    }
  );
};

export const useGetTaskTemplateDirectory = (directoryId: number) => {
  return useQuery(
    ['task-template-directory', directoryId],
    async () => {
      return await TaskTemplateAPI.getTaskTemplateDirectory({ directoryId });
    },
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0,
      enabled: !!directoryId
    }
  );
};
