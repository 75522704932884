import { getEnv } from 'services/apiEnv.service';
import { get, post, del, put } from 'services/request.service';
import {
  AddTaskTemplateDirectoryType,
  DeleteTaskTemplateDirectory,
  GetTaskTemplateLibraryType,
  TaskTeplateDirectoryType,
  UpdateTaskTemplateDirectory
} from './task-template.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/task-template-management`;

export const getTaskTemplateLibrary = async ({
  params
}: GetTaskTemplateLibraryType): Promise<TaskTeplateDirectoryType[]> => {
  return (
    await get({
      url: `${BASE_URL}/library`,
      params: { ...params }
    })
  ).data;
};

export const getTaskTemplateDirectory = async ({
  directoryId
}: {
  directoryId: number;
}): Promise<TaskTeplateDirectoryType> => {
  return (
    await get({
      url: `${BASE_URL}/directories/${directoryId}`
    })
  ).data;
};

export const addTaskTemplateDirectory = async ({ data }: AddTaskTemplateDirectoryType) => {
  return await post({
    url: `${BASE_URL}/directories`,
    method: 'POST',
    data
  });
};

export const deleteTaskTemplateDirectory = async ({ data }: DeleteTaskTemplateDirectory) => {
  return await del({
    url: `${BASE_URL}/directories/${data.directoryId}`,
    method: 'DELETE',
    data
  });
};

export const updateTaskTemplateDirectory = async ({ data }: UpdateTaskTemplateDirectory) => {
  return await put({
    url: `${BASE_URL}/directories/${data.directoryId}`,
    method: 'PUT',
    data
  });
};
