import { useMutation, UseMutationOptions } from 'react-query';
import { TaskTemplateAPI } from 'api';

export const useAddTaskTemplateDirectory = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.addTaskTemplateDirectory, { ...config });
};

export const useDeleteTaskTemplateDirectory = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.deleteTaskTemplateDirectory, { ...config });
};

export const useUpdateTaskTemplateDirectory = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.updateTaskTemplateDirectory, { ...config });
};
